export default {
  navigation: {
    '.container': {
      padding: ['0.5rem', '0.5rem', '0.5rem', '4.5rem 1rem'],
    },
    '.logo': {
      img: {
        filter: 'unset',
      },
    },
    '.smallNavMenu': {
      '.navItem': {
        color: 'black',
        margin: ['', '', '', '0.75rem', '1rem'],
        a: {
          fontSize: '1rem',
          ':hover': {
            color: 'black',
            textDecoration: 'underline',
          },
        },
      },
    },
    '.hamburger': {
      backgroundColor: 'rgb(255 255 255 / 42%)',
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      '.navMenuLogo': {
        position: ['', '', '', 'absolute'],
        width: ['', '', '', '35%'],
        left: '1rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%'],
        },
        transition: 'all ease-in-out 5s',
        ':hover': {
          transform: 'rotate(360deg)',
        },
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto'],
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
        },
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right',
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
        },
      },
    },
  },

  footer: {
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'black',
    },
    // '.multiButtonContainer': {
    //   border: 'none',
    //   a: {
    //     border: 'none!important',
    //   },
    // },
  },
  contactForm: {
    padding: '4rem 1rem',
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1.25rem',
      opacity: '0.8',
    },
    '.text': {
      fontStyle: 'italic',
      marginBottom: '3rem',
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid black',
      },
      '.submitBtn': {
        borderRadius: '0px',
      },
    },
  },

  homepageHero: {
    // backgroundColor: 'white',
    '.logoHero': {
      display: ['none', '', '', 'block'],
    },

    '.slick-slider': {
      width: ['100%', '100%', '100%', '60%'],
      order: ['', '', '', '2'],
      '.slick-prev, .slick-next': {
        color: 'white',
        bottom: '0rem',
        backgroundColor: 'rgb(30 57 124 / 35%)',
        height: '30px',
      },
      '.slick-prev': {
        left: '1rem',
      },
      '.slick-next': {
        left: '3rem',
      },
      '.slick-dots': {
        left: '1rem',
        right: 'unset',
        display: 'none',
      },
      '.slick-dots li button:before': {
        color: 'white',
        display: 'none',
      },
    },
    '.hero_content_container': {
      marginBottom: ['', '', '', '7rem'],
      '.title': {
        display: 'flex',
        flexDirection: 'column',
        fontSize: '2.5rem',
      },
      '.subtitle': {
        color: 'grey',
        fontWeight: 'bold',
        textTransform: 'initial',
      },

      '.text': {
        textTransform: 'uppercase',
        lineHeight: '1.75',
        marginBottom: '1rem',
      },
    },
  },
  homepageHeroShout: {
    backgroundColor: ['transparent', 'transparent', 'transparent'],
    position: ['', '', 'static', 'absolute'],
    marginBottom: ['2rem', '', 'unset'],
    transform: ['', '', 'unset', 'unset'],
    left: ['', '', '', '0rem'],
    padding: ['', '', '', '0rem'],
    bottom: ['', '', '', '2rem'],
    maxWidth: ['767px', '', '', '38%'],
    '.content': {
      padding: ['', '', '', '1rem 2rem'],
    },
    '.title': {
      letterSpacing: '2px',
      textTransform: 'capitalize',
      fontSize: '1rem',
      width: 'fit-content',
      '::after': {
        backgroundColor: 'black',
      },
    },
    '.text': {
      fontWeight: '300',
      fontSize: '1.4rem',
      marginY: '1rem',
    },
    '.imageContainer': {
      display: 'none',
    },
    '.shoutImage': {
      height: '200px',
    },
    '.shoutCTABtns': {
      marginBottom: '1rem',
      justifyContent: 'flex-end',
    },
    '.shoutCTA': {
      variant: 'buttons.secondary',
    },
    '.date': {
      color: 'primary',
    },
  },

  homepageAboutSection: {
    marginTop: ['', '', '', '2.5rem'],
    '.content': {
      width: ['', '', '65%'],
      '.title': {
        fontSize: '2.5rem',
        color: 'primary',
      },
      '.subtitle': {
        fontSize: '2rem',
        color: 'grey',
        fontWeight: 'bold',
      },
    },
    '.lazyload-wrapper': {
      width: ['', '', '35%'],
      '::before': {
        background: 'unset',
      },
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  homepageServicesSection: {
    marginTop: ['', '', '', '2.5rem'],
    '.content': {
      width: ['', '', '', '45%'],
      alignItems: 'flex-start',
      textAlign: 'left',
      backgroundColor: ['', '', '', '#eae9e5'],
      position: 'relative',
      left: ['', '', '', '5rem'],
      top: ['', '', '', '2rem'],
      '.title': {
        fontSize: '2.5rem',
        color: 'primary',
      },
      '.subtitle': {
        fontSize: '2rem',
        color: 'grey',
        fontWeight: 'bold',
      },
    },
    '.lazyload-wrapper': {
      width: ['', '', '', '55%'],
      img: {
        maxHeight: '65vh',
      },
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  homepageGallerySection: {
    '.content': {
      backgroundColor: ['', '', '', '#eae9e5'],
    },
    '.title': {
      fontSize: '2.5rem',
      lineHeight: '1',
      color: 'primary',
    },
    '.subtitle': {
      fontSize: '2rem',
      color: 'grey',
      fontWeight: 'bold',
      borderBottom: 'none',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  homepageContactUs: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    color: 'white',
    padding: '10rem 1rem',
    '.section': {
      alignItems: 'flex-start',
      textAlign: 'left',
      margin: '0rem 0rem 0rem 5%',
      maxWidth: 'unset',
      padding: '1rem',
      backgroundColor: 'rgb(255 255 255 / 38%)',
    },
    '.title': {
      textAlign: 'left',
      borderLeft: 'none',
      paddingLeft: '0rem',
      fontSize: ['1.75rem', '2rem', '2,5rem', '3rem'],
      textTransform: 'capitalize',
      color: '#535353',
    },
    '.subtitle': {
      color: '#7e7e7e',
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
    '.text': {
      textAlign: 'left',
      color: '#343434',
    },
    '.gatsby-link': {
      margin: '0 0 0 auto',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  //  ? ====  About page  ====

  aboutSection: {
    '.title': {
      fontSize: '3.5rem',
      lineHeight: '1',
      color: 'primary',
    },
  },

  //  ? ====  services page  ====

  gallery: {
    // '.albumsCTA ': {
    //   variant: 'buttons.secondary',
    // },
    '.albumsContainer': {
      display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black',
    },
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase',
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0',
    },
  },
}
