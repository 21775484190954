export default {
  heading: 'Spartan, serif',
  body: 'Spartan, san-serif',
  monospace: 'Menlo, monospace',
  display: 'Playfair Display, serif',
  display2: 'Tangerine, serif',
  googleFonts: [
    'Scheherazade:400,700',
    'Spartan:100,200,300,400,500,600,700,800,900',
    'Playfair Display:400,500,600,700,800,900',
  ],
  // customFamilies: [''],
  // customUrls: [''],
}
