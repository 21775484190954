export default {
  primary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    fontWeight: '500',
    ':hover': {
      backgroundColor: 'transparent',
      color: 'primary',
    },
  },
  secondary: {
    backgroundColor: 'transparent',
    padding: '0.5rem',
    color: 'primary',
    border: 'none',
    borderBottom: 'solid 2px',
    borderColor: 'primary',
    fontWeight: 'bold',
    display: 'flex',
    ':hover': {
      opacity: '0.4',
      backgroundColor: 'transparent',
      color: 'grey',
    },
  },
}
